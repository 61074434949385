"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTimestamp = exports["default"] = void 0;
// This is .js for preval

var versionType = "PROD";
var versionNumber = Math.floor(1726153177329 / 1000);
var shortCommitSha = "1481e202d";
var getTimestamp = exports.getTimestamp = function getTimestamp(version) {
  return +version.split('-')[1];
};
var _default = exports["default"] = "PROD-1726153177-1481e202d";